import { PageQuery } from "queries/Page";
import { GetStaticPropsContext } from "next";
import { createSubscription } from "components/hooks/usePreview";
import BlockMapper from "blocks/BlockMapper";
import { withAppLayout } from "components/core/withAppLayout";
import CtaBlock from "blocks/cta/CtaBlock";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import nextI18NextConfig from "../../next-i18next.config";

export async function getStaticProps(context: GetStaticPropsContext) {
    const query = {
        query: PageQuery('home', context.locale),
        preview: context.preview,
        withAppData: true,
        locale: context.locale
    };

    const data = await createSubscription(context, query);
    
    if (!data?.initialData?.page) {
        return {
            notFound: true,
        };
    }

    return {
        props: {
            data,
            seo: data.initialData.page.seo,
            appData: {
                ...data.initialData.header,
                ...data.initialData.footer
            },
            ...(await serverSideTranslations(context.locale, ['common'], nextI18NextConfig)),
        }
    };
}

function Home(props: any) {
    const { page } = props;

    return (
        <div>
            {page.content.map((block: any) => Object.keys(block).length > 0 && (
                <BlockMapper key={block.id} {...block} />
            ))}

            {page?.cta?.map((cta: any) => Object.keys(cta).length > 0 && (
                <section key={cta.id}>
                    <CtaBlock {...cta} />
                </section>
            ))}
        </div>
    );
}

export default withAppLayout(Home);